import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';

class Resume extends Component {
    render() {
        return(
            <div className="resume">
                <Grid align='center'>
                    <Cell align='center'>
                    <iframe src="https://drive.google.com/file/d/1JsohFdw7azQciRv99kCz3TAmrsTb6DeS/preview" width="960" height="600"></iframe>
                    </Cell>
                </Grid>
            </div>
        )
    }
}

export default Resume;